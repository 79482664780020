var contractors = [
    'Строительные технологии',
    'Левобережник',
    'Система-Сервис',
    'СК Строй',
    'Альянсстроймонтаж',
    'СМИТ-Ярцево',
    'ПромЭнергоСбыт',
    'ЭЛИС-Групп',
    'МЕГАВАТТ'
];